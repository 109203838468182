import digital_media_a from './img/digital_media_a.png'
import digital_media_b from './img/digital_media_b.png'
import below_a from './img/below_a.png'
import below_b from './img/below_b.png'
import posicionamiento_a from './img/posicionamiento_a.png'
import posicionamiento_b from './img/posicionamiento_b.png'
import outofhome_a from './img/outofhome_a.png'
import outofhome_b from './img/outofhome_b.png'
import especial_a from './img/especial_a.png'
import especial_b from './img/especial_b.png'
import eventos_a from './img/eventos_a.png'
import eventos_a_m from './img/eventos_a_m.png'
import eventos_b from './img/eventos_b.png'

import digitalPortada from './img/digital_portada.png'
import bellowPortada from './img/bellow_portada.png'
import posicionamientoPortada from './img/posicionamiento_portada.png'
import outofhomePortada from './img/outofhome_portada.png'
import especialesPortada from './img/especiales_portada.png'
import eventosPortada from './img/eventos_portada.png'

import digital from './img/digital.png'
import posicionamiento from './img/posicionamiento.png'
import outofhome from './img/outofhome.png'
import especiales from './img/especiales.png'
import eventos from './img/eventos.png'
import bellow from './img/bellow.png'

import c1 from './img/carousel/1.jpg'
import c2 from './img/carousel/2.jpg'
import c3 from './img/carousel/3.jpg'
import c4 from './img/carousel/4.jpg'
import c5 from './img/carousel/5.jpg'
import c6 from './img/carousel/6.jpg'
import c7 from './img/carousel/7.jpg'
import c8 from './img/carousel/8.jpg'
import c9 from './img/carousel/9.jpg'
import c10 from './img/carousel/10.jpg'
import c11 from './img/carousel/11.jpg'
import c12 from './img/carousel/12.jpg'
import c13 from './img/carousel/13.jpg'
import c14 from './img/carousel/14.jpg'

import Creatividad from './img/animations/creatividad.json'
import Branding from './img/animations/branding.json'
import Estrategia from './img/animations/estrategia.json'
import Servicios1 from './img/animations/servicios1.json'
import Servicios2 from './img/animations/servicios2.json'
import Staff from './img/animations/staff.json'
import BBVA from './img/animations/bbva.json'
import Golf from './img/animations/golf.json'
import Dirigible from './img/animations/dirigible.json'
import Espectacular from './img/animations/espectacular.json'
import Impresora from './img/animations/impresora.json'
import Rollos from './img/animations/rollos.json'
import Eventos from './img/animations/eventos.json'
import Corporativos from './img/animations/corporativos.json'
import Lanzamiento from './img/animations/lanzamiento.json'
import Bicivalla from './img/animations/bicivalla.json'

import fondo1a from './img/animations/fondo1a.png'
import fondo1b from './img/animations/fondo1b.png'
import fondo1c from './img/animations/fondo1c.png'
import fondo2a from './img/animations/fondo2a.png'
import fondo2b from './img/animations/fondo2b.png'
import fondo2c from './img/animations/fondo2c.png'
import fondo3a from './img/animations/fondo3a.png'
import fondo3b from './img/animations/fondo3b.png'
import fondo3c from './img/animations/fondo3c.png'

export const carousel = [ c1,c2,c3,c4,c5,c6,c7,c8,c9,c10,c11,c12,c13,c14 ]

export const services = [
    {
        key: 0,
        title: 'Digital media',
        header_image: digital_media_a,
        body_image: digital_media_b,
        desc: 'Construimos y proyectamos una estrategia digital integral, que enamore a tus audiencias, a través de herramientas, plataformas y contenidos creativos de alto impacto.',
        classColor: 'acc-salmon',
        backgroundButton: '',
        link: '/digital',
        body: digital,
        frontImg: digitalPortada,
        bullets: [
            {
                title: 'CREATIVIDAD',
                body: <>
                    <ul>
                        <li> Social media </li>
                        <li> Producción audiovisual </li>
                        <li> Contenido y publicidad nativa </li>
                        <li> Desarrollo e- commerce, web y apps </li>
                        <li> Desarrollo de sistemas de administración personalizados </li>
                    </ul>
                </>,
                animation: Creatividad,
                color: '#E47160',
                background: fondo2a
            },
            {
                title: 'BRANDING',
                body: <>
                    <ul>
                        <li> Benchmarking </li>
                        <li> Naming </li>
                        <li> Concepto creativo </li>
                        <li> Identidad corporativa </li>
                        <li> Slogan </li>
                        <li> Registro de marca </li>
                        <li> Manual de identidad </li>
                    </ul>
                </>,
                animation: Branding,
                color: '#E38F89',
                background: fondo2b
            },
            {
                title: 'ESTRATEGIA',
                body: <>
                    <ul>
                        <li> Inﬂuencers </li>
                        <li> Check up digital </li>
                        <li> Gestión de campañas </li>
                        <li> Marketing automation </li>
                    </ul>
                </>,
                animation: Estrategia,
                color: '#EAAEA8',
                background: fondo2c
            }
        ]
    },
    {
        key: 1,
        title: 'Below The Line',
        header_image: below_a,
        body_image: below_b,
        desc: 'Somos expertos en crear campañas que conectan con tu público. Generamos una mejor, más fluida e integrada experiencia en cada uno de los canales y puntos de contacto.',
        classColor: 'acc-green',
        backgroundButton: 'acc-salmon-dark',
        link: '/bellow',
        body: bellow,
        frontImg: bellowPortada,
        bullets: [
            {
                title: 'SERVICIOS',
                body: <>
                    <ul>
                        <li> Punto de venta </li>
                        <li> Animación </li>
                        <li> Street marketing </li>
                        <li> Distribución publicitaria casa por casa </li>
                        <li> Valla móvil, valla experiencia, vallas led e implementaciones especiales </li>
                    </ul>
                </>,
                animation: Servicios1,
                color: '#A7A67E',
                background: fondo3a
            },
            {
                title: '',
                body: <>
                    <ul>
                        <li>  Publiandantes, publiandantes led </li>
                        <li>  Rolling semáforo </li>
                        <li>  Activaciones en cine </li>
                        <li>  Dron show </li>
                        <li>  Sampling </li>
                        <li>  Bicivalla </li>
                        <li>  Motovalla </li>
                    </ul>
                </>,
                animation: Bicivalla,
                color: '#7F7E5E',
                background: fondo3b
            },
            {
                title: 'STAFF',
                body: <>
                    <ul>
                        <li> Animadores </li>
                        <li> Modelos </li>
                        <li> Edecanes </li>
                        <li> Promotores </li>
                        <li> Productor de eventos </li>
                        <li> Gestores  </li>
                        <li> Mistery shopper </li>
                    </ul>
                </>,
                animation: Staff,
                color: '#9E9C74',
                background: fondo3c
            }
        ]
    },
    {
        key: 2,
        title: 'Medios de posicionamiento',
        header_image: posicionamiento_a,
        body_image: posicionamiento_b,
        desc: 'Conseguimos que tu marca sea recordada y vista por millones de manera efectiva a través de canales novedosos, de calidad y con un alto impacto.',
        classColor: 'acc-blue',
        backgroundButton: 'acc-green-dark',
        link: '/posicionamiento',
        body: posicionamiento,
        frontImg: posicionamientoPortada,
        bullets: [
            {
                title: '',
                body: <>
                    <ul>
                        <li> BBVA TV </li>
                        <li> Cinemex y Cinépolis </li>
                        <li> Centros comerciales </li>
                        <li>  Aeropuertos </li>
                        <li>  Restaurantes “La Casa de Toño” </li>
                        <li>  Jets privados </li>
                    </ul>
                </>,
                animation: BBVA,
                color: '#3C6F7E',
                background: fondo1a
            },
            {
                title: '',
                body: <>
                    <ul>
                        <li>  Campos de golf </li>
                        <li>  Banners de led en restaurantes </li>
                        <li>  Radio regional </li>
                        <li>  Azteca Media </li>
                    </ul>
                </>,
                animation: Golf,
                color: '#739AA3',
                background: fondo1b
            }
        ]
    },
    {
        key: 3,
        title: 'Out of Home',
        header_image: outofhome_a,
        body_image: outofhome_b,
        desc: 'Llega a una gran audiencia de manera efectiva a través de canales tradicionales publicitarios. Consigue impacto inmediato y masivo.',
        classColor: 'acc-salmon',
        backgroundButton: 'acc-blue-dark',
        link: '/outofhome',
        body: outofhome,
        frontImg: outofhomePortada,
        bullets: [
            {
                title: '',
                body: <>
                    <ul>
                        <li> Espectaculares </li>
                        <li>  Vallas ﬁjas y digitales </li>
                        <li>  Bajopuente y cabeceras </li>
                        <li>  Pintamuros </li>
                        <li>  Camiones urbanos </li>
                        <li>   Metro </li>
                        <li>   Metrobús </li>
                        <li>   Mexibús </li>
                    </ul>
                </>,
                animation: Dirigible,
                color: '#E47160',
                background: fondo2a
            },
            {
                title: '',
                body: <>
                    <ul>
                        <li>  Tren ligero </li>
                        <li> Suburbano </li>
                        <li> Parabuses </li>
                        <li>  Sitios de taxi </li>
                        <li>  Dirigibles </li>
                        <li>  Aerostáticos </li>
                        <li>  Skybanner </li>
                        <li> Vía verde </li>
                    </ul>
                </>,
                animation: Espectacular,
                color: '#E38F89',
                background: fondo2b
            }
        ]
    },
    {
        key: 4,
        title: 'Servicios Especiales',
        header_image: especial_a,
        body_image: especial_b,
        desc: 'Brindamos un toque diferenciador a tu estrategia de marketing con publicidad innovadora y de alto impacto. Desarrollamos cualquier idea que tu imaginación sea capaz de concebir. ',
        classColor: 'acc-green',
        backgroundButton: 'acc-salmon-dark',
        link: '/especiales',
        body: especiales,
        frontImg: especialesPortada,
        bullets: [
            {
                title: '',
                body: <>
                    <ul>
                        <li>  Banners digitales led </li>
                        <li>  Brandeo de unidades </li>
                        <li>  Servicio de impresión </li>
                        <li>  Proyectos de señalización </li>
                        <li>  Anuncios luminosos  </li>
                        <li>  Fachadas publicitarias </li>
                    </ul>
                </>,
                animation: Rollos,
                color: '#A7A67E',
                background: fondo3a
            },
            {
                title: '',
                body: <>
                    <ul>
                        <li>  Volumétricos </li>
                        <li>  Marketing sensorial </li>
                        <li> <a href='https://mandarinapromocionales.mx/' target='_blank'> <div style={{ color: '#fff', textDecoration: 'underline' }}>Artículos promocionales</div> </a> </li>
                    </ul>
                </>,
                animation: Impresora,
                color: '#7F7E5E',
                background: fondo3b
            }
        ]
    },
    {
        key: 5,
        title: 'Eventos y exposiciones',
        header_image: eventos_a,
        body_image: eventos_b,
        desc: 'Obtén una experiencia a medida de tus necesidades. Nos encargamos desde una demostración en punto de venta, hasta activaciones.',
        classColor: 'acc-blue',
        backgroundButton: 'acc-green-dark',
        link: '/eventos',
        body: eventos,
        frontImg: eventosPortada,
        bullets: [
            {
                title: 'EVENTOS ',
                body: <>
                    <ul>
                        <li> Stand </li>
                        <li>  Display </li>
                        <li>  Corners </li>
                        <li>  Mobiliario de exhibición  </li>
                        <li>  Diseño industrial y de interiores </li>
                        <li>  Equipamiento </li>
                    </ul>
                </>,
                animation: Eventos,
                color: '#3C6F7E',
                background: fondo1a
            },
            {
                title: 'EVENTOS CORPORATIVOS',
                body: <>
                    <ul>
                        <li>  Seminarios </li>
                        <li>  Conferencias </li>
                        <li>  Call Center </li>
                        <li>  Convenciones </li>
                        <li>  Audio y video  </li>
                    </ul>
                </>,
                animation: Corporativos,
                color: '#739AA3',
                background: fondo1b
            },
            {
                title: 'LANZAMIENTO DE PRODUCTOS',
                body: <>
                    <ul>
                        <li>  Escenarios </li>
                        <li>  Escenografías </li>
                        <li>  Pasarelas </li>
                        <li>  Alfombra roja </li>
                    </ul>
                </>,
                animation: Lanzamiento,
                color: '#9AB7BF',
                background: fondo1c
            }
        ],
        final: true,
        imgHeaderMobile: eventos_a_m
    }
]